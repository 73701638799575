<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-main style="padding: 0 1rem !important;">

    <!-- filtros de busqueda y boton para agregar un contrato -->
    <div class="d-flex pt-3 filtros-flex">
      <v-text-field outlined type="number" dense class="me-2" label="Número" hide-details v-model="buscadorId"
        @input="listarContratos()" @keypress="validarLetra($event)">
      </v-text-field>

      <v-text-field class="filtros" outlined dense v-model="buscadorNombre" @input="listarContratos()" label="Nombre"
        hide-details>
      </v-text-field>

      <v-tooltip left color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small class="ms-2" fab color="success" v-bind="attrs" v-on="on" @mousedown.prevent="dialog = true">
            <v-icon> add </v-icon>
          </v-btn>
        </template>
        <span>Agregar</span>
      </v-tooltip>
    </div>

    <!-- dialogo para crear y actualizar un contrato -->
    <v-dialog v-model="dialog" :editar="false" persistent transition="dialog-bottom-transition" max-width="49.7rem">
      <v-card>
        <v-card-title class="fondoDialog">
          <span class="text-h6">{{ tituloContrato }}</span>
        </v-card-title>

        <!-- formulario para editar y crear un contrato -->
        <v-card-text style="padding-top: 0.7rem;">
          <validation-observer ref="observer" v-slot="{ invalid }">
            <v-form ref="nuevoContrato" class="mt-3" lazy-validation>

              <!-- Campos para el nombre y id del contrato -->
              <div class="d-flex mt-3 formulario-flex">
                <div class="d-flex align-items-center">
                  <validation-provider v-slot="{ errors }" :rules="{ required: true, idExistente: true, }">
                    <v-text-field outlined id="id" v-model="nuevoContrato.idContrato" dense :error-messages="errors" @paste.prevent
                      style="width: 21.5rem" label="Número" :disabled="disabled == 1" @keypress="validarLetra($event)">
                    </v-text-field>
                  </validation-provider>
                  <div class="d-flex align-items-center"
                    style="width: 2.6rem; padding-bottom: 1.4rem; padding-left: 0.13rem;">
                    <v-tooltip right color="blue darken-3">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon dark color="blue darken-3" fab v-bind="attrs" v-on="on">
                          help
                        </v-icon>
                      </template>
                      <span>Número del contrato</span>
                    </v-tooltip>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <validation-provider v-slot="{ errors }" :rules="{ required: true, }" validate-on-input>
                    <v-text-field v-model="nuevoContrato.nombreContrato" :error-messages="errors" style="width: 21.3rem;"
                      label="Nombre" dense outlined>
                    </v-text-field>
                  </validation-provider>
                  <div class="d-flex align-items-center"
                    style="width: 2.6rem; padding-bottom: 1.4rem; padding-left: 0.13rem;">
                    <v-tooltip right color="blue darken-3">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon dark color="blue darken-3" fab v-bind="attrs" v-on="on">
                          help
                        </v-icon>
                      </template>
                      <span>Nombre del contrato</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>

              <!-- Campos para las fechas y el manejo de biometría del contrato -->
              <div class="d-flex formulario-flex">
                <div class="d-flex align-items-center">
                  <v-menu ref="confirmarFechas" v-model="confirmarFechas" :close-on-content-click="false" min-width="auto"
                    :return-value.sync="fechas" transition="scale-transition" offset-y :nudge-top="25" :nudge-right="25">
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider>
                        <v-text-field :value="fechasTexto" label="Fecha inicial y final" id="fechas"
                          style="width: 21.5rem;" :clearable="true" readonly v-model="fechasTexto" v-bind="attrs"
                          v-on="on" dense outlined>
                          {{ fechasSeparadas }}
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <div>
                      <v-date-picker v-model="fechas" :active-picker.sync="activePicker" range locale="es-co"
                        :first-day-of-week="1" min="2018" :multiple="false" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="borrarFechas">BORRAR</v-btn>
                        <v-btn :disabled="!botonHabilitado" text color="primary" @click="$refs.confirmarFechas.save(fechas)">OK</v-btn>
                      </v-date-picker>
                    </div>
                  </v-menu>
                  <div class="d-flex align-items-center"
                    style="width: 2.6rem; padding-bottom: 1.4rem; padding-left: 0.13rem;">
                    <v-tooltip right color="blue darken-3">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon dark color="blue darken-3" fab v-bind="attrs" v-on="on">
                          help
                        </v-icon>
                      </template>
                      <span>Fecha de inicio y fecha de finalización del contrato</span>
                    </v-tooltip>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                    <v-select id="manejaBiometria" v-model="nuevoContrato.manejaBiometria" :items="tiposBiometrias"
                      label="Maneja biometría" :error-messages="errors" style="width: 21.3rem" dense outlined :menu-props="{offsetY:true}">
                    </v-select>
                  </validation-provider>
                  <div class="d-flex align-items-center"
                    style="width: 2.6rem; padding-bottom: 1.4rem; padding-left: 0.13rem;">
                    <v-tooltip right color="blue darken-3">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon dark color="blue darken-3" fab v-bind="attrs" v-on="on">
                          help
                        </v-icon>
                      </template>
                      <span>Elija si o no de acuerdo al contrato</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>

              <!-- Campos para el monto y el tope mensual del contrato -->
              <div class="d-flex formulario-flex">
                <div class="d-flex align-items-center">
                  <validation-provider v-slot="{ errors }" :rules="{
                    montoMayorAlTopeMensual: {
                      topeMensual: nuevoContrato.topeMensual ? nuevoContrato.topeMensual.replace(/[^0-9-]+/g, '') > 0 ? nuevoContrato.topeMensual.replace(/[^0-9-]+/g, '') : 0 : 0
                    }
                  }">
                    <v-text-field id="monto" v-model="nuevoContrato.monto" style="width: 21.5rem;"
                      :error-messages="errors" label="Monto" dense outlined @input="numeroFormateado($event, 'monto')"
                      @keypress="validarLetra($event)">
                    </v-text-field>
                  </validation-provider>
                  <div class="d-flex align-items-center"
                    style="width: 2.6rem; padding-bottom: 1.4rem; padding-left: 0.13rem;">
                    <v-tooltip right color="blue darken-3">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon dark color="blue darken-3" fab v-bind="attrs" v-on="on">
                          help
                        </v-icon>
                      </template>
                      <span>Defina el valor total del contrato</span>
                    </v-tooltip>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <validation-provider>
                    <v-text-field id="topeMensual" v-model="nuevoContrato.topeMensual" style="width: 21.3rem;"
                      label="Tope mensual" dense outlined @input="numeroFormateado($event, 'topeMensual')"
                      @keypress="validarLetra($event)">
                    </v-text-field>
                  </validation-provider>
                  <div class="d-flex align-items-center"
                    style="width: 2.6rem; padding-bottom: 1.4rem; padding-left: 0.13rem;">
                    <v-tooltip right color="blue darken-3">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon dark color="blue darken-3" fab v-bind="attrs" v-on="on">
                          help
                        </v-icon>
                      </template>
                      <span>Defina el valor del tope mensual al contrato</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>

              <!-- Campos para el tipo de movimiento y el requiere autorización del contrato -->
              <div class="d-flex formulario-flex">
                <div class="d-flex align-items-center">
                  <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                    <v-select id="tipoMovimientos" v-model="nuevoContrato.tipoMovimiento" :items="tiposMovimientos"
                      label="Tipo de movimiento" :error-messages="errors" style="width: 21.5rem;" dense outlined :menu-props="{offsetY:true}">
                    </v-select>
                  </validation-provider>
                  <div class="d-flex align-items-center"
                    style="width: 2.6rem; padding-bottom: 1.4rem; padding-left: 0.16rem;">
                    <v-tooltip right color="blue darken-3">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon dark color="blue darken-3" fab v-bind="attrs" v-on="on">
                          help
                        </v-icon>
                      </template>
                      <span>Novedad tipo movimiento</span>
                    </v-tooltip>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                    <v-select id="requiereAutorizaciones" v-model="nuevoContrato.requiereAutorizacion"
                      :items="tiposAutorizaciones" label="Requiere autorización" :error-messages="errors"
                      style="width: 21.3rem;" dense outlined :menu-props="{offsetY:true}">
                    </v-select>
                  </validation-provider>
                  <div class="d-flex align-items-center"
                    style="width: 2.6rem; padding-bottom: 1.4rem; padding-left: 0.13rem;">
                    <v-tooltip right color="blue darken-3">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon dark color="blue darken-3" fab v-bind="attrs" v-on="on">
                          help
                        </v-icon>
                      </template>
                      <span>Elija si o no de acuerdo al contrato</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>

              <!-- Campo para el objeto del contrato -->
              <div class="d-flex">
                <div class="d-flex align-items-center">
                  <v-textarea id="objeto" v-model="nuevoContrato.objeto" label="Objeto" hide-details
                    style="width: 45.4rem;" rows="3" no-resize dense outlined :maxlength="maximoCaracteres">
                  </v-textarea>
                  <div class="d-flex align-items-center"
                    style="width: 2.6rem; padding-bottom: 1.4rem; padding-left: 0.13rem;">
                    <v-tooltip right color="blue darken-3">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon dark color="blue darken-3" fab v-bind="attrs" v-on="on">
                          help
                        </v-icon>
                      </template>
                      <span>Descripción y detalles del contrato</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>

              <!-- Campo para registrar el conteo de los caracteres escritos dentro del campo Objeto -->
              <div class="d-flex justify-end mr-8" style="font-size: smaller;">
                <span>{{ contadorCaracteres }} / {{ maximoCaracteres }}</span>
              </div>

              <!-- Botones de cerrar, guardar y actualizar que se activan cuando se abre el dialogo para contrato-->
              <div class="d-flex justify-end">
                <v-btn color="error" text @click="reset()">
                  CERRAR
                </v-btn>
                <v-btn v-if="!editar" depressed @click="crearContrato()" :disabled="invalid" color="success" text>
                  {{ textoBoton }}
                </v-btn>
                <v-btn v-else depressed @click="actualizarContrato()" color="success" text>
                  {{ textoBoton }}
                </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Tabla que muestra los registros del contrato -->
    <v-data-table :loading="loading" fixed-header :headers="headersContratos" :items="listaDeContratos"
      :page.sync="pagina" :items-per-page.sync="itemsPorPagina" :server-items-length="totalPaginas" class="elevation mt-4"
      height="66vh" :footer-props="footerPropsListaContratos">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" v-bind:key="item.idContrato">
            <td class="text-center">{{ item.idContrato }}</td>
            <td>{{ item.nombreContrato }}</td>
            <td class="text-center">
              <span v-if="item.requiereAutorizacion == true">Si</span>
              <span v-if="item.requiereAutorizacion == false">No</span>
            </td>
            <td class="text-center">
              <span v-if="item.manejaBiometria == true">Si</span>
              <span v-if="item.manejaBiometria == false">No</span>
            </td>
            <td>
              <span v-if="item.tipoMovimiento == 'D'">Salida directa</span>
              <span v-if="item.tipoMovimiento == 'F'">Factura</span>
              <span v-if="item.tipoMovimiento == 'S'">Remisión</span>
            </td>
            <td>
              <span v-if="item.eliminado == false"><v-icon color="success"> check_circle </v-icon> Activo</span>
              <span v-if="item.eliminado == true"><v-icon color="error"> cancel </v-icon> Inactivo</span>
            </td>
            <td class="text-center">
              <v-icon color="orange" @click="editarContrato(item)">border_color</v-icon>
              <v-btn icon @click="abrirDialogoEstado(item)">
                <v-icon v-if="item.eliminado == false" color="error"> person_add_disabled </v-icon>
                <v-icon v-if="item.eliminado == true" color="success"> how_to_reg </v-icon>
              </v-btn>
            </td>
            <td class="text-center">
              <v-icon medium color="blue" @click="abrirTrazabilidad(item)">backup_table</v-icon>
            </td>
            <td class="text-center">
              <v-icon medium color="blue" @click="detallesDelContrato(item)">library_add</v-icon>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

    <!-- dialogo para ver los detalles de un contrato -->
    <v-dialog class="home" v-model="dialogDetalles" persistent transition="dialog-bottom-transition" max-width="610">
      <v-card>
        <v-card-title class="fondoDialog">
          <span class="text-h6">Detalles del contrato número {{ nuevoContrato.idContrato }}</span>
        </v-card-title>

        <v-card-text style="padding-top: 20px;">
          <validation-observer ref="observer">
            <v-form ref="contratoSelecionado" lazy-validation>

              <!-- Campo para mostrar el detalle del atributo nombreContrato del contrato seleccionado-->
              <div class="d-flex align-items-center">
                <v-list style="width: 100%">
                  <v-list-item>
                    <v-list-item-title style="word-wrap: break-word; white-space: pre-wrap;"><strong>Nombre: </strong>
                     {{ nuevoContrato.nombreContrato }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </div>

              <!-- Campo para mostrar los detalles del atributo objeto del contrato seleccionado -->
              <div class="d-flex align-items-center">
                <v-list style="width: 100%">
                  <v-list-item>
                    <v-list-item-title
                      style="word-wrap: break-word; white-space: pre-wrap; max-height: 5rem; overflow-y: auto;"><strong>Objeto: </strong>
                      <span v-html="formatoCampo(nuevoContrato.objeto)"></span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </div>
              <v-divider class="mt-4" color="grey"></v-divider>

              <!-- Campos para mostrar los detalles de los atributos del contrato seleccionado-->
              <div class="d-flex mt-4 formulario-flex">
                <div style="width: 17.6rem">
                  <v-list>
                    <v-list-item>
                      <v-list-item-title><strong>Maneja biometría: </strong>
                        <span v-if="nuevoContrato.manejaBiometria == true">Si</span>
                        <span v-if="nuevoContrato.manejaBiometria == false">No</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title><strong>Requiere autorización: </strong>
                        <span v-if="nuevoContrato.requiereAutorizacion == true">Si</span>
                        <span v-if="nuevoContrato.requiereAutorizacion == false">No</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title><strong>Tipo de movimiento: </strong>
                        <span v-if="nuevoContrato.tipoMovimiento == 'D'">Salida directa</span>
                        <span v-if="nuevoContrato.tipoMovimiento == 'F'">Factura</span>
                        <span v-if="nuevoContrato.tipoMovimiento == 'S'">Remisión</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title><strong>Estado: </strong>
                        <span v-if="nuevoContrato.eliminado === false"><v-icon color="success"> check_circle </v-icon>
                          Activo</span>
                        <span v-if="nuevoContrato.eliminado === true"><v-icon color="error"> cancel </v-icon> Inactivo</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </div>

                <div style="width: 17.6rem">
                  <v-list>
                    <v-list-item>
                      <v-list-item-title><strong>Fecha inicial: </strong>
                        <span v-html="formatoCampo(nuevoContrato.fechaInicial)"></span></v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title><strong>Fecha final: </strong>
                        <span v-html="formatoCampo(nuevoContrato.fechaFinal)"></span> </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title><strong>Tope mensual: </strong>
                        <span v-html="formatoCampo(nuevoContrato.topeMensual)"></span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title><strong>Monto: </strong>
                        <span v-html="formatoCampo(nuevoContrato.monto)"></span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </div>
              </div>

              <!-- Boton para cerrar y acede al método reset para limpiar el dialog inmediatamente se cierra-->
              <div class="d-flex justify-end">
                <v-btn color="error" text @click="reset()">
                  CERRAR
                </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Campo para activar o inactivar el estado del contrato -->
    <v-dialog v-model="dialogoCambiarEstado" persistent transition="dialog-bottom-transition" max-width="22.7rem">
      <v-card>
        <v-card-title class="fondoDialog">
          <span class="text-h6"> {{ mensajeEstado }} </span>
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-end" style="padding-top: 1.3rem">
            <v-btn text color="error" @click="dialogoCambiarEstado = false">No</v-btn>
            <v-btn depressed color="success" text @click="cambiarEstado()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Campo para mostrar la trazabilidad del contrato -->
    <v-dialog v-model="dialogTrazabilidad" persistent transition="dialog-bottom-transition" max-width="67.4rem">
      <v-card>
        <v-card-title class="fondoDialog">
          <span class="text-h6">Trazabilidad del contrato número {{ contratoSelecionado.idContrato }}</span>
        </v-card-title>

        <v-card-text style="padding-top: 1.2rem;">
          <v-data-table fixed-header :headers="headersContratosConTrazabilidad" :items="listaTrazabilidad"
            :page.sync="paginaTrazabilidad" :items-per-page.sync="itemsPorPaginaTrazabilidad" height="24.3rem"
            class="elevation trazabilidad" :sort-by="'time'" :sort-desc="true" :footer-props="footerPropsTrazabilidad">
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.rev_id">
                  <td>{{ nombreCampos[item.campo] }}</td>
                  <td>
                    <template v-if="['monto', 'tope_mensual'].includes(item.campo)">
                      <span>{{ formattedCurrency(item.anterior) }}</span>
                    </template>
                    <template v-if="item.campo === 'requiere_autorizacion'">
                      <span v-if="item.anterior === true">Si</span>
                      <span v-if="item.anterior === false">No</span>
                    </template>
                    <template v-if="item.campo === 'maneja_biometria'">
                      <span v-if="item.anterior === true">Si</span>
                      <span v-if="item.anterior === false">No</span>
                    </template>
                    <template v-if="item.campo === 'tipo_movimiento'">
                      <span v-if="item.anterior === 'D'">Salida directa</span>
                      <span v-if="item.anterior === 'F'">Factura</span>
                      <span v-if="item.anterior === 'S'">Remisión</span>
                    </template>
                    <template v-if="item.campo === 'eliminado'">
                      <span v-if="item.anterior === false">Activo</span>
                      <span v-if="item.anterior === true">Inactivo</span>
                    </template>
                    <template v-if="item.campo === 'objeto'">
                      <template v-if="item.anterior" style="color: black;">
                        {{ item.anterior.length > 24 ? item.anterior.substr(0, 21) + '...' : item.anterior }}
                        <template v-if="item.anterior.length > 24">
                          <v-btn text color="primary" @click="mensajeSeleccionado = item.anterior; dialogMensaje = true;">
                            <v-icon color="blue"> search </v-icon>
                          </v-btn>
                        </template>
                      </template>
                    </template>
                    <template v-if="validarCampos(item)">
                      {{ item.anterior }}
                    </template>
                  </td>
                  <td>
                    <template v-if="['monto', 'tope_mensual'].includes(item.campo)">
                      <span>{{ formattedCurrency(item.actual) }}</span>
                    </template>
                    <template v-if="item.campo === 'requiere_autorizacion'">
                      <span v-if="item.actual === true">Si</span>
                      <span v-if="item.actual === false">No</span>
                    </template>
                    <template v-if="item.campo === 'maneja_biometria'">
                      <span v-if="item.actual === true">Si</span>
                      <span v-if="item.actual === false">No</span>
                    </template>
                    <template v-if="item.campo === 'tipo_movimiento'">
                      <span v-if="item.actual === 'D'">Salida directa</span>
                      <span v-if="item.actual === 'F'">Factura</span>
                      <span v-if="item.actual === 'S'">Remisión</span>
                    </template>
                    <template v-if="item.campo === 'eliminado'">
                      <span v-if="item.actual === false">Activo</span>
                      <span v-if="item.actual === true">Inactivo</span>
                    </template>
                    <template v-if="item.campo === 'objeto'">
                      <template v-if="item.actual" style="color: black;">
                        {{ item.actual.length > 22 ? item.actual.substr(0, 19) + '...' : item.actual }}
                        <template v-if="item.actual.length > 22">
                          <v-btn text color="primary" @click="mensajeSeleccionado = item.actual; dialogMensaje = true;">
                            <v-icon color="blue"> search </v-icon>
                          </v-btn>
                        </template>
                      </template>
                    </template>
                    <template v-if="validarCampos(item)">
                      {{ item.actual }}
                    </template>
                  </td>
                  <td>{{ item.username }}</td>
                  <td class="text-letf">{{ formatDate(item.time) }}</td>
                </tr>
              </tbody>
            </template>
            <template v-slot:footer.page-text="items">
              {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
          </v-data-table>

          <div class="d-flex justify-end">
            <v-btn color="error" text @click=" dialogTrazabilidad = false">
              CERRAR
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Campo para mostrar el objeto del contrato en un dialog, que se abre por medio del icono de la columna trazabilidad -->
    <v-dialog v-model="dialogMensaje" max-width="32rem" scrollable>
      <v-card style="padding-top: 1.3rem;">
        <v-card-text>
          {{ mensajeSeleccionado }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="error" text @click=" dialogMensaje = false">
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-main>
</template>
   
   <!-- #################################################################################### -->
   <!-- ###### Sección de scripts                                                     ###### -->
   <!-- #################################################################################### -->
   
<script>
import { extend, ValidationObserver, ValidationProvider, setInteractionMode, } from 'vee-validate';
import { mapMutations, mapState } from "vuex";
import { Role } from "../../../../router/role.js";
import * as rules from 'vee-validate/dist/rules';
import { required, regex, digits, email, min_value } from 'vee-validate/dist/rules';
import Vue from "vue"

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

// validaciones con vee-validate
setInteractionMode('eager')
extend('min_value', min_value);
extend('required', {
  ...required,
  message: 'Este campo es requerido',
});

// Definir la función para parsear el monto de formato moneda a número
const parseMonedaToNumber = (value) => {
  return parseFloat(value.replace(/[^0-9-]+/g, ""));
};

// Valida que el monto sea mayor al tope mensual
extend('montoMayorAlTopeMensual', {
  validate: (value, args) => {
    const topeMensual = parseFloat(args.topeMensual) || 0;
    const monto = parseMonedaToNumber(value);
    return isNaN(monto) || topeMensual === 0 || monto > topeMensual; // validar solo si el monto es un número válido y topeMensual es mayor o igual a cero
  },
  params: ['topeMensual'],
  message: 'El Monto debe ser mayor que el Tope Mensual',
});

// Valida si un id de contrato existe en la base de datos y sugiere tres números mayores al id máximo.
extend('idExistente', {
  validate: async (value) => {
    let validacionId = true
    let mensaje = "el número del contrato ya existe"
    await Vue.prototype.$http.get("msa-administration/dpContr/existeId/" + value)
      .then((result) => {
        validacionId = !result.data
      })
      .catch((error) => {
        console.log(error);
      });
    if (!validacionId) {
      // si el id no existe, obtener los próximos tres números de contrato posibles
      await Vue.prototype.$http.get("msa-administration/dpContr/idMaximo")
        .then(async (result) => {
          // obtener el siguiente número posible
          let siguienteNumero = result.data + 1;
          // obtener los siguientes tres números posibles
          let recomendacionNumeros = [];
          for (let i = siguienteNumero; i <= siguienteNumero + 2; i++) {
            recomendacionNumeros.push(i);
          }
          mensaje =
            "El número de contrato ya existe, intenta con: " +
            recomendacionNumeros.join(", ");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return validacionId ? true : mensaje;
  },
});

extend('digits', {
  ...digits,
  message: 'El {_field_} debe tener {length} números. ({_value_})',
});

extend('regex', {
  ...regex,
  message: 'El {_field_} {_value_} no tiene el formato correcto',
})

extend('email', {
  ...email,
  message: 'Email invalido',
})

export default {
  name: "Contrato",
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      lastmodifiedby: "",
      dialogMensaje: false,
      mensajeSeleccionado: "",
      showTooltip: false,
      buscadorId: '',
      buscadorNombre: '',
      tituloContrato: 'Crear contrato',
      textoBoton: "GUARDAR",
      activePicker: null,
      dialogDetalles: false,
      dialog: false,
      dialogTrazabilidad: false,
      tituloTrazabilidad: '',
      editar: false,
      dialogoCambiarEstado: false,
      confirmarFechas: false,
      contratoSelecionado: {},
      disabled: 0,
      nuevoContrato: {
        idContrato: '',
        nombreContrato: '',
        fechaInicial: [],
        fechaFinal: [],
        manejaBiometria: '',
        monto: '',
        topeMensual: '',
        tipoMovimiento: '',
        requiereAutorizacion: '',
        objeto: ''
      },
      maximoCaracteres: 4096,
      caracteresDobles: ["𝕏"],
      estado: false,
      mensajeEstado: '',
      fechas: [],
      userRoles: {},
      roles: Role,
      listaDeContratos: [],
      listaTrazabilidad: [],
      pagina: 1,
      totalPaginas: 0,
      itemsPorPagina: 10,
      itemsPorPaginaTrazabilidad: 7,
      totalPaginasTrazabilidad: 0,
      paginaTrazabilidad: 1,
      idEmpresa: 1,
      loading: false,
      footerPropsListaContratos: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Items por página:',
        'show-current-page': true,
        'show-first-last-page': true,
      },
      footerPropsTrazabilidad: {
        'show-current-page': true,
        'show-first-last-page': true,
      },
      tiposBiometrias: [
        { text: 'Si', value: true },
        { text: 'No', value: false }
      ],
      tiposMovimientos: [
        { text: 'Salida directa', value: 'D' },
        { text: 'Factura', value: 'F' },
        { text: 'Remisión', value: 'S' }
      ],
      tiposAutorizaciones: [
        { text: 'Si', value: true },
        { text: 'No', value: false }
      ],
      headersContratos: [
        { text: "NÚMERO", align: "center", sortable: false, width: "1rem" },
        { text: "NOMBRE", sortable: false, width: "11rem" },
        { text: "REQUIERE AUTORIZACIÓN", align: "center", sortable: false, width: "1rem" },
        { text: "MANEJA BIOMETRÍA", align: "center", sortable: false, width: "1rem" },
        { text: "TIPO DE MOVIMIENTO", align: "left", sortable: false, width: "7.4rem" },
        { text: "ESTADO", sortable: false, width: "6.9rem" },
        { text: "ACCIONES", align: "center", sortable: false, width: "5.8rem" },
        { text: "TRAZABILIDAD", align: "center", sortable: false, width: "1rem" },
        { text: "DETALLES", align: "center", sortable: false, width: "1rem" }
      ],
      headersContratosConTrazabilidad: [
        { text: "CAMPO", sortable: false, width: "17%" },
        { text: "VALOR ANTERIOR", sortable: false, width: "27%" },
        { text: "VALOR NUEVO", sortable: false, width: "27%" },
        { text: "USUARIO", sortable: false, width: "10%" },
        { text: "FECHA DE MODIFICACIÓN", sortable: false, width: "19%" },
      ],
      nombreCampos: {
        'nombre_contrato': 'Nombre',
        'fecha_inicial': 'Fecha de inicio',
        'fecha_final': 'Fecha de finalización',
        'monto': 'Monto',
        'tope_mensual': 'Tope mensual',
        'requiere_autorizacion': 'Requiere autorización',
        'maneja_biometria': 'Maneja biometría',
        'tipo_movimiento': 'Tipo de movimiento',
        'id_empresa': 'Código empresa',
        'eliminado': 'Estado',
        'objeto': 'Objeto'
      },
    }
  },

  created() {
    this.userRoles = this.auth.roles;
    this.listarContratos();
  },

  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),

    // Separa las fechas con un "|" y las devuelve como una cadena de texto
    fechasSeparadas() {
      if (Array.isArray(this.fechas)) {
        return this.fechas.join(' | ');
      }
      return '';
    },
    fechasTexto: {
      get() {
        return this.fechasSeparadas;
      },
      set(value) {
        if (!value) {
          this.fechas = [];
        } else {
          this.fechas = value.split(' | ');
        }
      }
    },

    // Le agrega un formato de moneda colombiana a los números de los campos monto y tope mensual cuando se muestran en la trazabilidad
    formattedCurrency() {
      return (value) => {
        if (value !== null && value !== undefined && !isNaN(parseFloat(value))) {
          return parseFloat(value).toLocaleString('es-CO', { style: 'currency', currency: 'COP' }).replace(/,\d{2}$/, '');
        }
        return '';
      };
    },

    // Si el campo en los detalles del contrato no tiene datos para mostrar le muestra el mensaje en el return
    formatoCampo() {
      return function (valor) {
        if (!valor) {
          return '<span style="font-style: italic; color: grey;">Sin datos</span>';
        } else {
          return valor;
        }
      }
    },

    // Contador de caracteres para el campo Objeto
    contadorCaracteres() {
      let contador = 0;
      const texto = this.nuevoContrato.objeto;

      if (texto) {
        for (let i = 0; i < texto.length; i++) {
          const char = texto[i];

          if (this.caracteresDobles.includes(char)) {
            contador += 2;
          } else {
            contador += 1;
          }
        }
      }
      return contador;
    },

    // Computed usado para verificar si en el calendario existen dos fechas seleccionadas
    botonHabilitado() {
      return Array.isArray(this.fechas) && this.fechas.length === 2;
    },
  },

  watch: {
    pagina: function () {
      this.listarContratos();
    },
    itemsPorPagina: function () {
      this.pagina = 1;
      this.listarContratos();
    },
    'buscadorId': function () {
      this.pagina = 1;
      this.listarContratos();
    },
    'buscadorNombre': function () {
      this.pagina = 1;
      this.listarContratos();
    },
    // Establece 'paginaTrazabilidad' en 1 cuando la variable 'listaTrazabilidad' cambia
    'listaTrazabilidad': function () {
      this.paginaTrazabilidad = 1;
    },
    fechas(newFechas) {
      // Comprueba si 'newFechas' es un array y tiene longitud 2
      if (Array.isArray(newFechas) && newFechas.length === 2) {
        const fechaInicio = new Date(newFechas[0]);
        const fechaFin = new Date(newFechas[1]);
        // Comprueba si la fecha final es anterior a la fecha de inicio
        if (fechaFin < fechaInicio) {
          // Intercambia las fechas en 'newFechas'
          const temp = newFechas[0];
          newFechas[0] = newFechas[1];
          newFechas[1] = temp;
          // Actualiza 'fechas' con las fechas intercambiadas
          this.fechas = newFechas;
        }
      }
    },
  },

  mounted() {
    this.fechasTexto = this.fechasSeparadas;
    this.lastmodifiedby = this.auth.username.toUpperCase();
  },

  methods: {
    ...mapMutations([
      "updateAuth",
      "hideNotify",
      "showSuccess",
      "showBusy",
      "hideBusy",
    ]),
  
    /**
    * Valida la tecla presionada en un evento de entrada.
    * Comprueba si la tecla presionada no es "e", "-", "+" o ",".
    * Si no es ninguna de esas teclas, se verifica si la tecla es un número o el símbolo "$".
    * Si no es un número ni el símbolo "$", se previene la acción por defecto del evento.
    * En caso de que la tecla sea "e", "-", "+" o ",", también se previene la acción por defecto del evento.
    * @param {object} event - El evento de entrada que contiene la tecla presionada.
    */
    validarLetra(event) {
      const teclaPresionada = event.key
      if (teclaPresionada.toLowerCase() !== "e" && teclaPresionada !== "-" && teclaPresionada !== "+" && teclaPresionada !== ",") {
        const regex = /[0-9$]/g;
        if (!regex.test(teclaPresionada)) {
          event.preventDefault();
        }
      } else {
        event.preventDefault();
      }
    },

    /**
    * Borra las fechas seleccionadas.
    * Establece el valor de la variable "fechasTexto" a una cadena vacía.
    * Establece el valor de la variable "fechas" a null.
    */
    borrarFechas() {
      this.fechasTexto = '';
      this.fechas = null;
    },

    /**
    * Formatea un número ingresado en un campo específico.
    * Establece el valor del campo especificado en el objeto "nuevoContrato" como una cadena vacía.
    * Aplica un formato de número al valor ingresado y lo asigna al campo especificado en el objeto "nuevoContrato".
    * @param {string} e - Valor ingresado en el campo.
    * @param {string} campo - Nombre del campo a formatear en el objeto "nuevoContrato", que se usa en las lineas 143 y 162
    * para los campos tope mensual y monto.
    */
    numeroFormateado(e, campo) {
      this.nuevoContrato[campo] = ''
      const tmpValor = e.replace(/[^0-9-]+/g, "")
      const formatter = new Intl.NumberFormat('es-CO', {
        currency: 'COP'
      });
      this.nuevoContrato[campo] = '$ ' + formatter.format(tmpValor);
    },

    /**
    * Valida si un campo debe ser excluido según una lista predefinida.
    * @param {object} item - Objeto que contiene el campo a validar.
    * @returns {boolean} - Indica si el campo debe ser excluido (true) o no (false).
    */
    validarCampos(item) {
      const excluir = [
        'nombre_contrato',
        'fecha_inicial',
        'fecha_final',
        'id_empresa',
      ]
      return excluir.includes(item.campo)
    },

    /**
    * Formatea una fecha y hora en el formato deseado para la trazabilidad.
    * @param {string} dateTimeString - Cadena que representa la fecha y hora a formatear.
    * @returns {string} - Cadena formateada en el formato 'YYYY-MM-DD HH:mm am/pm'.
    */
    formatDate(dateTimeString) {
      const dateTime = new Date(dateTimeString);
      const hours = dateTime.getHours();
      const minutes = dateTime.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedMonth = (dateTime.getMonth() + 1).toString().padStart(2, '0');
      const formattedDay = dateTime.getDate().toString().padStart(2, '0');
      const formattedDateTime = `${dateTime.getFullYear()}-${formattedMonth}-${formattedDay} ${formattedHours.toString().padStart(2, '0')}:${formattedMinutes} ${ampm}`;
      return formattedDateTime;
    },

    /**
    * Crea un nuevo contrato.
    * Se requiere que los campos obligatorios estén especificados para crear el contrato.
    */
    crearContrato() {
      const horaActual = new Date;
      // Verificar si los campos requeridos se han especificado para crear el contrato
      if (this.nuevoContrato.topeMensual !== undefined) {
        let fechaInicial = null, fechaFinal = null;

        // Obtener las fechas del campo v-data-picker si están disponibles
        if (this.fechas && this.fechas.length === 2) {
          [fechaInicial, fechaFinal] = this.fechas;
        }

        // Realizar la solicitud POST para crear el contrato
        this.$http
          .post("msa-administration/contrato/crear", {
            fechaInicial: fechaInicial ? `${fechaInicial}T00:00:00-05:00` : null,
            fechaFinal: fechaFinal ? `${fechaFinal}T00:00:00-05:00` : null,
            idContrato: Number(this.nuevoContrato.idContrato),
            nombreContrato: this.nuevoContrato.nombreContrato,
            empresa: {
              idEmpresa: this.enterprise.code,
            },
            manejaBiometria: this.nuevoContrato.manejaBiometria,
            monto: Number(this.nuevoContrato.monto ? this.nuevoContrato.monto.replace(/[^0-9-]+/g, "") : null),
            topeMensual: Number(this.nuevoContrato.topeMensual ? this.nuevoContrato.topeMensual.replace(/[^0-9-]+/g, "") : null),
            tipoMovimiento: this.nuevoContrato.tipoMovimiento,
            requiereAutorizacion: this.nuevoContrato.requiereAutorizacion,
            eliminado: this.estado,
            objeto: this.nuevoContrato.objeto || null,
            createdby: this.lastmodifiedby,
            createDate: horaActual,
          })
          .then(() => {
            // Actualizar la lista de contratos y restablecer los campos
            this.listarContratos();
            this.reset();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    /**
    * Actualiza un contrato existente.
    * Se requiere que los campos obligatorios estén especificados para actualizar el contrato.
    */
    actualizarContrato() {
      const horaActual = new Date;
      // Verificar si los campos requeridos se han especificado para actualizar el contrato
      if (this.nuevoContrato.topeMensual !== undefined) {
        let fechaInicial = null, fechaFinal = null;

        // Obtener las fechas del campo v-data-picker si están disponibles
        if (this.fechas && this.fechas.length === 2) {
          [fechaInicial, fechaFinal] = this.fechas;
        }

        // Realizar la solicitud PUT para actualizar el contrato
        this.$http
          .put("msa-administration/contrato/actualizar", {
            empresa: {
              idEmpresa: this.enterprise.code,
            },
            idContrato: Number(this.nuevoContrato.idContrato),
            nombreContrato: this.nuevoContrato.nombreContrato,
            fechaInicial: fechaInicial ? `${fechaInicial}T00:00:00-05:00` : null,
            fechaFinal: fechaFinal ? `${fechaFinal}T00:00:00-05:00` : null,
            manejaBiometria: this.nuevoContrato.manejaBiometria,
            monto: Number(this.nuevoContrato.monto ? this.nuevoContrato.monto.replace(/[^0-9-]+/g, "") : null),
            topeMensual: Number(this.nuevoContrato.topeMensual ? this.nuevoContrato.topeMensual.replace(/[^0-9-]+/g, "") : null),
            tipoMovimiento: this.nuevoContrato.tipoMovimiento,
            requiereAutorizacion: this.nuevoContrato.requiereAutorizacion,
            objeto: this.nuevoContrato.objeto || null,
            eliminado: this.estado,
            lastmodifiedby: this.lastmodifiedby,
            lastmodifieddate: horaActual,
          })
          .then(() => {
            // Actualizar la lista de contratos y restablecer los campos
            this.listarContratos();
            this.reset();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    /**
    * Muestra los detalles de un contrato específico en un cuadro de diálogo.
    * Copia los datos del contrato seleccionado al objeto "nuevoContrato" y abre el cuadro de diálogo de detalles.
    * @param {Object} item - El contrato del cual se mostrarán los detalles.
    */
    detallesDelContrato(item) {
      this.nuevoContrato = { ...item };
      this.dialogDetalles = true;
    },

    /**
    * Prepara el formulario para editar un contrato existente.
    * Copia los datos del contrato seleccionado al formulario de edición y configura los valores correspondientes.
    * @param {Object} item - El contrato que se va a editar.
    */
    editarContrato(item) {
      // Copiar los datos del contrato seleccionado al formulario de edición
      this.nuevoContrato = { ...item };
      if (Array.isArray(item.fechas)) {
        this.fechas = item.fechas;
      }
      // Verificar si las fechas de inicio y fin están presentes y asignarlas al campo correspondiente
      if (item.fechaInicial && item.fechaInicial.length > 0 && item.fechaFinal && item.fechaFinal.length > 0) {
        this.fechas = [item.fechaInicial, item.fechaFinal];
      }
      // Configurar los valores para la interfaz de edición del contrato
      this.tituloContrato = 'Actualizar contrato';
      this.textoBoton = 'ACTUALIZAR';
      this.dialog = true;
      this.editar = true;
      this.disabled = 1;
    },

    /**
    * Restablece los valores de los campos y las variables relacionadas al contrato.
    * Establece los valores predeterminados y cierra los cuadros de diálogo abiertos.
    * Reinicia el estado de edición y activación de campos.
    * Reinicia el estado del componente de observación (refs.observer).
    */
    reset() {
      this.nuevoContrato.idContrato = '';
      this.nuevoContrato.nombreContrato = '';
      this.nuevoContrato.fechaInicial = [];
      this.nuevoContrato.fechaFinal = [];
      this.nuevoContrato.manejaBiometria = '';
      this.nuevoContrato.monto = '';
      this.nuevoContrato.topeMensual = '';
      this.nuevoContrato.tipoMovimiento = '';
      this.nuevoContrato.requiereAutorizacion = '';
      this.nuevoContrato.objeto = '';
      this.fechas = [];
      this.tituloContrato = 'Crear contrato';
      this.textoBoton = 'GUARDAR';
      this.dialog = false;
      this.dialogDetalles = false;
      this.editar = false;
      this.dialogoCambiarEstado = false;
      this.disabled = 0;
      this.$refs.observer.reset();
    },

    /**
    * Obtiene la lista de contratos mediante una solicitud HTTP GET al servidor.
    * Los contratos se obtienen con paginación y se filtran según los parámetros especificados.
    * Después de obtener los datos, se formatean ciertos campos y se asignan a la lista de contratos del componente.
    * Se actualiza el número total de páginas y se registra el resultado en la consola.
    * Además, se controla el estado de carga durante la solicitud.
    */
    listarContratos() {
      this.loading = true;
      this.$http
        .get(`msa-administration/contrato/listar`, {
          params: {
            page: this.pagina - 1,
            size: this.itemsPorPagina,
            nombre: `${this.buscadorNombre.toLowerCase()}`,
            id: `${this.buscadorId}`,
            idEmpresa: this.enterprise.code,
          },
        })
        .then(({ data }) => {
          // Formatear ciertos campos de los contratos obtenidos
          data.content.forEach(element => {
            element.fechas = `${element.fechaInicial} - ${element.fechaFinal}`;
            const formatter = new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' });
            element.monto = formatter.format(element.monto).replace(/,\d{2}$/, '');
            element.topeMensual = formatter.format(element.topeMensual).replace(/,\d{2}$/, '');
            if (element.fechaInicial && element.fechaFinal) {
              element.fechas = `${element.fechaInicial} - ${element.fechaFinal}`;
            } else {
              element.fechas = ' ';
            }
          });
          this.listaDeContratos = data.content;
          // Actualizar el número total de páginas
          this.totalPaginas = data.totalElements;
          this.loading = false;
        }).catch(error => {
          console.log(error)
          this.loading = false;
        })
    },

    /**
    * Abre el diálogo para cambiar el estado de un contrato.
    * Establece la propiedad 'dialogoCambiarEstado' en true para mostrar el diálogo.
    * Guarda el contrato seleccionado en la propiedad 'contratoSelecionado'.
    * Este método se invoca cuando se desea cambiar el estado de un contrato.
    * @param {object} item - El contrato seleccionado.
    */
    abrirDialogoEstado(item) {
      this.dialogoCambiarEstado = true;
      this.contratoSelecionado = item;
      this.mensajeEstado = this.contratoSelecionado.eliminado === false ? '¿Desea inactivar el contrato?' : '¿Desea activar el contrato?';
    },

    /**
    * Cambia el estado de un contrato.
    * Actualiza el estado del contrato seleccionado ('contratoSelecionado') según la lógica definida.
    * Realiza una solicitud HTTP PUT al servidor para actualizar el estado del contrato.
    * Después de la actualización exitosa, se registra el mensaje y se actualiza la lista de contratos.
    */
    cambiarEstado() {
      const horaActual = new Date;
      this.contratoSelecionado.eliminado = (this.contratoSelecionado.eliminado == false) ? true : false;
      const cambiarEst = {
        idContrato: this.contratoSelecionado.idContrato,
        eliminado: this.contratoSelecionado.eliminado,
        lastmodifiedby: this.lastmodifiedby,
        lastmodifieddate: horaActual,
      }
      this.$http
        .put(`msa-administration/contrato/actualizarEstado`, cambiarEst)
        .then(() => {
          this.dialogoCambiarEstado = false;
          this.listarContratos();
        }).catch(error => {
          console.log(error);
        })
    },

    /**
   * Abre el diálogo de trazabilidad de un contrato.
   * Establece la propiedad 'dialogTrazabilidad' en true para mostrar el diálogo.
   * Guarda el contrato seleccionado en la propiedad 'contratoSelecionado'.
   * Realiza una solicitud HTTP GET al servidor para obtener la información de trazabilidad del contrato.
   * La información obtenida se guarda en la propiedad 'listaTrazabilidad'.
   * Después de obtener la información, se registra en la consola.
   * En caso de error en la solicitud, se maneja el error y se registra en la consola.
   * @param {object} item - El contrato seleccionado.
   */
    abrirTrazabilidad(item) {
      this.dialogTrazabilidad = true;
      this.contratoSelecionado = item;
      this.$http
        .get(`msa-administration/contrato/trazabilidad/${this.contratoSelecionado.idContrato}`)
        .then(response => {
          this.listaTrazabilidad = response.data;
        }).catch((error) => {
          console.log(error);
        });
    },
  }
}

</script>
   
   
   <!-- #################################################################################### -->
   <!-- ###### Sección de estilos                                                     ###### -->
   <!-- #################################################################################### -->
   
<style scoped>
::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .elevation .v-data-footer {
  width: 100%;
}

::v-deep .elevation .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}

::v-deep .trazabilidad .v-data-footer {
  padding-top: 10px;
  justify-content: end;
  border: none;
}

::v-deep .trazabilidad .v-data-footer__select {
  display: none;
}

::v-deep .elevation .v-data-table__wrapper {
  border: 1px solid #f7f6f6;
}

.fondoDialog {
  background-color: #1867c0;
  color: white;
}

::v-deep #objeto {
  margin: 5px;
}

.formulario-flex {
  flex-wrap: nowrap;
}

@media (max-width: 700px) {
  .formulario-flex {
    flex-wrap: wrap !important;
  }
}

.filtros-flex {
  flex-wrap: nowrap;
}

@media (max-width: 700px) {
  .filtros-flex {
    flex-wrap: wrap !important;
  }

  .filtros-flex .filtros {
    margin-top: 10px;
  }
}
</style> 